import { Locale, type LocalizedString } from "../Locale.js";

export const cartPathMap = {
	[Locale.cs]: "kosik" as const,
} satisfies LocalizedString;

export const cartPaymentDeliveryPathMap = {
	[Locale.cs]: "kosik/doprava-a-platba" as const,
} satisfies LocalizedString;

export const cartAddressPathMap = {
	[Locale.cs]: "kosik/adresa" as const,
} satisfies LocalizedString;

import type { OrderVoucher } from "../OrderVoucher.js";

export function getSpreadedVouchers(vouchers: OrderVoucher[], randomUUID: () => string): OrderVoucher[] {
	const spreadedVouchers: OrderVoucher[] = [];

	for (const orderVoucher of vouchers) {
		for (let i = 0; i < orderVoucher.quantity; i++) {
			spreadedVouchers.push({
				...orderVoucher,
				id: randomUUID(),
				quantity: 1,
			});
		}
	}

	return spreadedVouchers;
}

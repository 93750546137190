export enum Locale {
	cs = "cs",
}

export type LocalizedString = Record<Locale, string>;

export type LocalizedStringArray = Record<Locale, string[]>;

export type LocalizedBoolean = Record<Locale, boolean>;

export type LocalizedID = Record<Locale, string>;

import type { ProductPrices } from "./ProductPrices.js";

export enum PriceLevel {
	Basic = "Basic",
	Advanced = "Advanced",
	Premium = "Premium",
}

export const priceLevelKeyMap: Record<PriceLevel, Required<keyof ProductPrices>> = {
	[PriceLevel.Basic]: "basic",
	[PriceLevel.Advanced]: "advanced",
	[PriceLevel.Premium]: "premium",
};

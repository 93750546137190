export function drawerAnimate(
	isOpen: boolean,
	animatedDrawer: HTMLDivElement | undefined,
	position: "left" | "right",
	duration: number,
): Animation | undefined {
	if (!animatedDrawer) {
		return undefined;
	}

	const keyframes = isOpen
		? position === "right"
			? [{ transform: "translateX(100%)" }, { transform: "translateX(0)" }]
			: [{ transform: "translateX(-100%)" }, { transform: "translateX(0)" }]
		: position === "right"
			? [{ transform: "translateX(0)" }, { transform: "translateX(100%)" }]
			: [{ transform: "translateX(0)" }, { transform: "translateX(-100%)" }];

	return animatedDrawer.animate(keyframes, {
		duration,
		easing: "ease-out",
		fill: "forwards",
	});
}

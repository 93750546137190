import type { OrderVoucher } from "../OrderVoucher.js";
import type { VoucherProduct } from "../VoucherProduct.js";

export function transformVoucherProductToOrderVoucher(
	id: string,
	voucher: VoucherProduct,
	quantity: number,
): OrderVoucher {
	return {
		id,
		orderVoucherProduct: {
			id: voucher.id,
			name: voucher.name,
			value: voucher.value,
		},
		quantity,
	};
}

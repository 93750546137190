import { Locale, type LocalizedString } from "./Locale.js";

export enum CreamColor {
	Blue = "Blue",
	Green = "Green",
	White = "White",
	Red = "Red",
	Pink = "Pink",
	Purple = "Purple",
	Gray = "Gray",
	Yellow = "Yellow",
}

export const creamColorNameMap: Record<CreamColor, LocalizedString> = {
	[CreamColor.Blue]: {
		[Locale.cs]: "modrá",
	},
	[CreamColor.Green]: {
		[Locale.cs]: "zelená",
	},
	[CreamColor.White]: {
		[Locale.cs]: "bílá",
	},
	[CreamColor.Red]: {
		[Locale.cs]: "červená",
	},
	[CreamColor.Pink]: {
		[Locale.cs]: "růžová",
	},
	[CreamColor.Purple]: {
		[Locale.cs]: "fialová",
	},
	[CreamColor.Gray]: {
		[Locale.cs]: "šedá",
	},
	[CreamColor.Yellow]: {
		[Locale.cs]: "žlutá",
	},
};

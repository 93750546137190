import { roundToPlaces } from "../../utils/roundToPlaces.js";
import type { OrderProduct } from "../OrderProduct.js";
import { ProductOption, productOptionKeyMap } from "../ProductOption.js";
import { taxLevelValueMap } from "../TaxLevel.js";
import { calculatePriceForProductOption } from "./calculatePriceForProductOption.js";

export function getPriceOfOrderProduct({ product, quantity, options }: OrderProduct): {
	withTax: number;
	withoutTax: number;
} {
	const baseWithTax = product.price.withTax;
	const withoutTaxDivider = 1 + taxLevelValueMap[product.price.taxLevel] / 100;

	if (!options) {
		const totalWithTax = baseWithTax * quantity;
		return {
			withTax: totalWithTax,
			withoutTax: totalWithTax / withoutTaxDivider,
		};
	}

	const priceOfOptions = Object.values(ProductOption).reduce((acc, option) => {
		const optionKey = productOptionKeyMap[option];
		if (!(optionKey in options)) {
			return acc;
		}

		const value = options[optionKey];

		return acc + calculatePriceForProductOption(baseWithTax, option, value);
	}, 0);

	const totalWithTax = (baseWithTax + priceOfOptions) * quantity;
	const totalWithoutTax = totalWithTax / withoutTaxDivider;

	return {
		withTax: roundToPlaces(totalWithTax, 2),
		withoutTax: roundToPlaces(totalWithoutTax, 2),
	};
}

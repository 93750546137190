import { Locale, type LocalizedString } from "../../../../core/schema/Locale.js";
import {
	cartAddressPathMap,
	cartPathMap,
	cartPaymentDeliveryPathMap,
} from "../../../../core/schema/paths/cartPathMap.js";

export enum CartStep {
	Overview = "Overview",
	PaymentDelivery = "PaymentDelivery",
	Address = "Address",
	ReadyToSend = "ReadyToSend",
}

export const cartStepOrderMap = Object.values(CartStep).reduce(
	(acc, step, index) => ({ ...acc, [step]: index }),
	// eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
	{} as Record<CartStep, number>,
);

export function getNextStep(step: CartStep): CartStep {
	const currentStepIndex = cartStepOrderMap[step];
	const [nextStep] = Object.entries(cartStepOrderMap).find(([_, index]) => index === currentStepIndex + 1) ?? [];
	if (nextStep) {
		return nextStep as CartStep;
	}
	return step;
}

export function getPreviousStep(step: CartStep): CartStep {
	const currentStepIndex = cartStepOrderMap[step];
	const [previousStep] = Object.entries(cartStepOrderMap).find(([_, index]) => index === currentStepIndex - 1) ?? [];
	if (previousStep) {
		return previousStep as CartStep;
	}
	return step;
}

export const cartStepNameMap: Record<Exclude<CartStep, CartStep.ReadyToSend>, LocalizedString> = {
	[CartStep.Overview]: {
		[Locale.cs]: "Krabice",
	},
	[CartStep.PaymentDelivery]: {
		[Locale.cs]: "Vyzvednutí a platba",
	},
	[CartStep.Address]: {
		[Locale.cs]: "Fakturační údaje",
	},
};

export const cartStepPathMap: Record<CartStep, LocalizedString> = {
	[CartStep.Overview]: cartPathMap,
	[CartStep.PaymentDelivery]: cartPaymentDeliveryPathMap,
	[CartStep.Address]: cartAddressPathMap,
	[CartStep.ReadyToSend]: cartAddressPathMap,
};

import type { ProductOption } from "../ProductOption.js";
import type { ProductOptionValueTypes } from "../ProductOption.js";
import { type ProductOptionPrice, productOptionPrices } from "../productOptionPrices.js";
import { isProductOptionUsed } from "./isProductOptionUsed.js";

export function calculatePriceForProductOption(
	basePrice: number,
	option: ProductOption,
	optionValue: ProductOptionValueTypes[ProductOption],
): number {
	if (!isProductOptionUsed(option, optionValue)) {
		return 0;
	}

	const price = productOptionPrices[option] as ProductOptionPrice<ProductOption>;
	switch (price.type) {
		case "percentage":
			return Math.round(basePrice * (price.percent / 100));
		case "fixed":
			return price.value;
		case "per-value-precentage":
			return Math.round((basePrice * price.percent(optionValue)) / 100);
		case "per-value-fixed":
			return price.value(optionValue);
	}
}

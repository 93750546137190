import type { VoucherValue } from "../VoucherValue.js";
import { VoucherValueType } from "../VoucherValueType.js";

export function getVoucherDiscount(value: VoucherValue, totalWithTax: number): number {
	if (value.type === VoucherValueType.ValueOnly) {
		return value.absolute;
	}

	const percentageDiscount = Math.round(totalWithTax * (value.percentageValue / 100));
	return Math.min(value.maxAbsolute, percentageDiscount);
}

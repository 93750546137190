import { getContext } from "svelte";
import type { CartStore } from "../stores/cart/CartStore.js";

/**
 * Automatically set key in context by renderer in cli.mts and createHtmlTemplate.ts
 * Contains cart store.
 */
export const cartStoreContextKey = "cartStore";

export function getCartStore(): CartStore {
	return getContext(cartStoreContextKey);
}

<script lang="ts">
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import { onMount } from "svelte";

	export let enabled: boolean;

	const { SMARTLOOK_PROJECT_KEY, SMARTLOOK_REGION } = getGlobalSettings();

	onMount(async () => {
		if (enabled) {
			const { default: Smartlook } = await import("smartlook-client");
			Smartlook.init(SMARTLOOK_PROJECT_KEY, {
				region: SMARTLOOK_REGION as "eu",
			});
			// TODO Add user data when login is implemented
		}
	});
</script>

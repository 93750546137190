import type { Cart } from "./Cart.js";
import { type CartStep, cartStepOrderMap } from "./CartStep.js";
import { fieldsToFillInPerStep } from "./fieldsToFillInPerStep.js";

export function ensureCartIsInCorrectStep<T extends Cart>(cart: T): T {
	const stepsWithFields = Object.keys(fieldsToFillInPerStep) as CartStep[];
	const stepsWithFieldsOrdered = stepsWithFields.sort(
		(first, second) => cartStepOrderMap[first] - cartStepOrderMap[second],
	);

	for (const step of stepsWithFieldsOrdered) {
		const stepIndex = cartStepOrderMap[step];
		const cartStepIndex = cartStepOrderMap[cart.step];
		if (stepIndex >= cartStepIndex) {
			break;
		}

		for (const field of fieldsToFillInPerStep[step]) {
			if (cart.order[field] === undefined) {
				cart.step = step;
				break;
			}
		}
	}

	return cart;
}

import { Locale, type LocalizedString } from "./Locale.js";

export enum PaymentType {
	GoPayPaymentCard = "GoPayPaymentCard",
	GoPayGPay = "GoPayGPay",
	GoPayApplePay = "GoPayApplePay",
	OnDelivery = "OnDelivery",
	OnInvoice = "OnInvoice",
}

export const b2cPaymentTypes = [
	PaymentType.GoPayApplePay,
	PaymentType.GoPayGPay,
	PaymentType.GoPayPaymentCard,
	PaymentType.OnDelivery,
] as const;

export const b2bPaymentTypes = [PaymentType.OnDelivery, PaymentType.OnInvoice] as const;

export type B2cPaymentType = (typeof b2cPaymentTypes)[number];
export type B2bPaymentType = (typeof b2bPaymentTypes)[number];

export const paymentTypeNameMap: Record<PaymentType, LocalizedString> = {
	[PaymentType.GoPayPaymentCard]: { [Locale.cs]: "Kartou" },
	[PaymentType.GoPayGPay]: { [Locale.cs]: "Google Pay" },
	[PaymentType.GoPayApplePay]: { [Locale.cs]: "Apple Pay" },
	[PaymentType.OnDelivery]: { [Locale.cs]: "Dobírkou" },
	[PaymentType.OnInvoice]: { [Locale.cs]: "Na fakturu" },
};

export const paymentTypeLongNameMap: Record<PaymentType, LocalizedString> = {
	[PaymentType.GoPayPaymentCard]: { [Locale.cs]: "Platební kartou" },
	[PaymentType.GoPayGPay]: { [Locale.cs]: "Google Pay" },
	[PaymentType.GoPayApplePay]: { [Locale.cs]: "Apple Pay" },
	[PaymentType.OnDelivery]: { [Locale.cs]: "Platba na místě hotově nebo kartou" },
	[PaymentType.OnInvoice]: { [Locale.cs]: "Úhrada na fakturu převodem" },
};

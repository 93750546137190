import type { OrderProduct } from "../OrderProduct.js";
import type { OrderProductOptions } from "../OrderProductOptions.js";
import { OrderProductStatus } from "../OrderProductStatus.js";
import type { ProductForCustomer } from "../Product.js";
import type { ProductSize } from "../ProductSize.js";
import { getPriceForProductForCustomerOfSize } from "./getPriceForProductForCustomerOfSize.js";

export function transformProductToOrderProduct(
	id: string,
	product: ProductForCustomer,
	quantity: number,
	size: ProductSize,
	options?: OrderProductOptions,
): OrderProduct {
	const price = getPriceForProductForCustomerOfSize(product, size);
	if (!price) {
		throw new Error("Price not found");
	}

	return {
		id,
		product: {
			id: product.id,
			name: product.name,
			allergens: product.allergens,
			description: product.description,
			quantityFromWhichToProduceAtCentral: product.quantityFromWhichToProduceAtCentral,
			categoryIds: product.categoryIds,
			price,
			producedOnePieceAtATime: product.producedOnePieceAtATime,
			permittedPickUpDates: product.permittedPickUpDates,
			enablePermittedPickUpDates: product.enablePermittedPickUpDates,
			sizeLabel: product.sizeLabel,
		},
		options,
		size,
		quantity,
		status: OrderProductStatus.ToDo,
	};
}

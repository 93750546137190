<script lang="ts">
	import arrowDown from "../../../core/assets/icons/arrow-down.svg?raw";
	import Icon from "./Icon.svelte";
	import type { ActionReturn } from "svelte/action";

	export let announcement: string;

	let expanded = false;
	function toggleExpand(): void {
		expanded = !expanded;
	}

	let showExpand = false;
	function announcementExpander(node: HTMLElement): ActionReturn {
		const resiveObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				const target = entry.target as HTMLElement;
				showExpand = target.scrollHeight > target.clientHeight || target.offsetWidth < target.scrollWidth || expanded;
			}
		});
		resiveObserver.observe(node, { box: "content-box" });
		const mutationsObserver = new MutationObserver((mutations) => {
			for (const mutation of mutations) {
				if (mutation.attributeName === "data-expanded") {
					const isExpanded = node.getAttribute("data-expanded") === "true";
					node.style.height = isExpanded ? `${node.scrollHeight}px` : "";
				}
			}
		});
		mutationsObserver.observe(node, { attributes: true });

		return {
			destroy(): void {
				resiveObserver.disconnect();
				mutationsObserver.disconnect();
			},
		};
	}
</script>

<button
	type="button"
	class="bg-primary fixed left-0 right-0 top-0 z-40 grid transition-transform"
	on:click={toggleExpand}
	class:shadow-lg={expanded}
	disabled={!showExpand}
	class:grid-cols-[1fr,2rem]={showExpand}
>
	<span
		use:announcementExpander
		class="h-8 items-start overflow-hidden text-ellipsis p-1 px-4 text-center text-white transition-[height] md:px-24 lg:px-48"
		class:whitespace-nowrap={!expanded}
		class:pb-4={expanded}
		data-expanded={expanded}
	>
		{announcement}
	</span>
	{#if showExpand}
		<Icon icon={arrowDown} class="m-1 h-6 w-6 text-white {expanded ? 'rotate-180' : ''}" />
	{/if}
</button>
<div class="mb-8"><!-- Dummy element to take up space --></div>

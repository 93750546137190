<script lang="ts">
	import cross from "../../../../core/assets/icons/cross.svg?raw";
	import arrowLeft from "../../../../core/assets/icons/arrow-left.svg?raw";
	import Icon from "../Icon.svelte";
	import { scrollLockStore } from "../../stores/scrollLockStore.js";
	import { drawerAnimate } from "./drawerAnimate.js";
	import { drawerBackdropAnimate } from "./drawerBackdropAnimate.js";

	export let isOpen = false;
	export let position: "left" | "right";
	export let onClose: () => boolean | Promise<boolean> = () => true;
	export let onHide: () => boolean | Promise<boolean> = () => true;
	export let dontShowCloseButton = false;
	export let closeButtonMessage = "Zrušit";
	export let showHideButton = false;
	export let noPadding = false;
	export let noBackdrop = false;
	export let noAnimation = false;

	const animationTime = 300;

	let drawerClass = "";
	export { drawerClass as class };

	let animatedDrawer: HTMLDivElement | undefined;
	let animatedBackdrop: HTMLButtonElement | undefined;
	let isVisible = false;

	$: {
		if (isOpen && !$scrollLockStore) {
			scrollLockStore.set(isOpen);
		}
	}
	$: {
		if (!isOpen) {
			scrollLockStore.set(isOpen);
		}
	}

	async function close(): Promise<void> {
		const shouldClose = await onClose();

		if (shouldClose) {
			isOpen = false;
		}
	}
	async function hide(): Promise<void> {
		const shouldHide = await onHide();

		if (shouldHide) {
			isOpen = false;
		}
	}

	let animationTimeout: number | undefined;
	function animate(
		isOpen: boolean,
		animatedDrawer: HTMLDivElement | undefined,
		animatedBackdrop: HTMLButtonElement | undefined,
	): void {
		clearTimeout(animationTimeout);
		if (isOpen) {
			isVisible = true;
			if (!animatedDrawer || !animatedBackdrop) {
				return;
			}

			if (typeof window !== "undefined") {
				animationTimeout = window.setTimeout(() => {
					drawerAnimate(isOpen, animatedDrawer, position, animationTime);
					drawerBackdropAnimate(isOpen, animatedBackdrop, animationTime);
				}, 50);
			}
		} else {
			if (typeof window !== "undefined") {
				animationTimeout = window.setTimeout(() => {
					isVisible = false;
				}, animationTime);
			}
			if (!animatedDrawer || !animatedBackdrop) {
				return;
			}

			drawerAnimate(isOpen, animatedDrawer, position, animationTime);
			drawerBackdropAnimate(isOpen, animatedBackdrop, animationTime);
		}
	}

	$: if (!noAnimation) {
		animate(isOpen, animatedDrawer, animatedBackdrop);
	}
</script>

{#if noAnimation ? isOpen : isVisible}
	<button
		bind:this={animatedBackdrop}
		type="button"
		tabindex="-1"
		class="{!noBackdrop && 'bg-secondary-200/75 backdrop-blur-sm'} {!noAnimation &&
			'opacity-0'} fixed inset-0 z-40 cursor-default"
		on:click|self={showHideButton ? hide : close}
	></button>
	<div
		bind:this={animatedDrawer}
		class="fixed bottom-0 top-0 w-full sm:w-auto {position === 'right' ? 'right-0' : 'left-0'} z-50 {!noAnimation
			? position === 'right'
				? 'translate-x-[100%]'
				: '-translate-x-[100%]'
			: ''}"
		class:flex-row-reverse={position === "left"}
	>
		<div
			class="relative h-full overflow-y-auto bg-white shadow-[-8px_4px_24px_0_rgba(0,0,0,0.1)] {drawerClass} {position ===
			'right'
				? 'ml-auto'
				: 'mr-auto'}"
		>
			{#if noPadding}
				<slot />
			{:else}
				<div class="px-[.8rem] py-12 min-[450px]:p-14">
					<slot />
				</div>
			{/if}
			{#if !dontShowCloseButton}
				<button
					type="button"
					on:click={close}
					class="absolute right-4 top-6 flex items-center gap-1 font-semibold sm:right-8"
				>
					{closeButtonMessage}
					<Icon icon={cross} class="h-4 w-4" />
				</button>
			{/if}
			{#if showHideButton}
				<button
					type="button"
					on:click={hide}
					class="absolute left-4 top-6 flex items-center gap-1 font-semibold sm:left-8"
				>
					<Icon icon={arrowLeft} class="h-4 w-4" />
					Zavřít panel
				</button>
			{/if}
		</div>
	</div>
{/if}

<script lang="ts">
	import Icon from "./Icon.svelte";
	import homeIcon from "../../../core/assets/icons/home.svg?raw";
	import Link from "./Link.svelte";
	import type { Breadcrumb } from "../../../core/schema/Breadcrumb.js";
	import { pageSlugContextKey } from "../contexts/pageSlugContextKey.js";
	import { getContext } from "svelte";

	export let breadcrumbs: Breadcrumb[];

	const pageSlug = getContext<string>(pageSlugContextKey);
</script>

{#if breadcrumbs.length > 0}
	<nav class="container my-1">
		<ul class="flex items-center gap-2.5 overflow-x-auto">
			{#each breadcrumbs as { url, label }, index}
				<li class="shrink-0">
					<Link
						class="text-4xs sm:text-2xs flex gap-2.5 text-nowrap leading-loose"
						href={url}
						noUnderline={url === `/${pageSlug}`}
					>
						{#if index === 0}
							<Icon icon={homeIcon} class="text-theme" />
						{/if}
						<span>
							{label}
						</span>
					</Link>
				</li>
				{#if index < breadcrumbs.length - 1}
					<li class="text-theme text-xl leading-loose">•</li>
				{/if}
			{/each}
		</ul>
	</nav>
{/if}

export function drawerBackdropAnimate(
	isOpen: boolean,
	animatedBackdrop: HTMLButtonElement | undefined,
	duration: number,
): Animation | undefined {
	if (!animatedBackdrop) {
		return undefined;
	}

	const keyframes = isOpen ? [{ opacity: 0 }, { opacity: 1 }] : [{ opacity: 1 }, { opacity: 0 }];

	return animatedBackdrop.animate(keyframes, {
		duration,
		easing: "ease-out",
		fill: "forwards",
	});
}

<script lang="ts">
	import type { ButtonProps } from "./ButtonProps.js";
	import { generateButtonClasses } from "./ButtonClasses.js";
	import Icon from "./Icon.svelte";

	type $$Props = ButtonProps;

	$: ({
		squared = false,
		active = false,
		isSmallForMobile = false,
		icon,
		iconFront = false,
		iconClass,
		disabled = false,
		variant = "primary",
		center = false,
		...restProps
	} = $$restProps as $$Props);

	$: type = restProps.type ?? "button";
	$: btnClasses = [
		restProps.class,
		generateButtonClasses({ variant, disabled, squared, active, isSmallForMobile, center }),
	]
		.filter(Boolean)
		.join(" ");
</script>

<button {...restProps} class={btnClasses} {type} {disabled} on:click on:mousedown on:blur>
	{#if icon && iconFront}
		<Icon {icon} class="{disabled ? 'text-secondary-300' : ''} {iconClass}" />
	{/if}
	<slot />
	{#if icon && !iconFront}
		<Icon
			{icon}
			class="{disabled ? 'group-hover:text-secondary-300 transition-colors duration-300' : ''} {iconClass}"
		/>
	{/if}
</button>

import { Locale, type LocalizedString } from "./Locale.js";

export enum ProductLabelStatus {
	ToDo = "ToDo",
	Ready = "Ready",
}

export const productLabelStatusNameMap: Record<ProductLabelStatus, LocalizedString> = {
	[ProductLabelStatus.ToDo]: { [Locale.cs]: "TODO" },
	[ProductLabelStatus.Ready]: { [Locale.cs]: "Připravená" },
};

import { type Readable, type StartStopNotifier, type Writable, writable } from "svelte/store";

export interface LocalStorageStoreValue<Value> {
	value: Value;
	loaded: boolean;
}

export type LocalStorageStore<Value> = Readable<LocalStorageStoreValue<Value>> &
	Omit<Writable<Value>, keyof Readable<LocalStorageStoreValue<Value>>>;

export function localStorageWritable<Value>(
	localStorageKey: string,
	initialValue: Value,
	start?: StartStopNotifier<LocalStorageStoreValue<Value>> | undefined,
): LocalStorageStore<Value> {
	const loaded = typeof window !== "undefined";
	const initial: LocalStorageStoreValue<Value> = {
		value: initialValue,
		loaded,
	};
	if (typeof localStorage === "undefined") {
		const { subscribe, set: _set, update: _update } = writable(initial, start);
		return {
			subscribe,
			set(value: Value): void {
				_set({ value, loaded });
			},
			update(updater): void {
				_update(({ value }) => {
					return {
						value: updater(value),
						loaded,
					};
				});
			},
		};
	}

	const storedValue = localStorage.getItem(localStorageKey);

	const {
		subscribe,
		set: _set,
		update: _update,
	} = writable(storedValue !== null ? { value: JSON.parse(storedValue) as Value, loaded } : initial, start);

	function saveValueToLocalStorage(value: Value): void {
		if (value === null || value === undefined) {
			localStorage.removeItem(localStorageKey);
		} else {
			localStorage.setItem(localStorageKey, JSON.stringify(value));
		}
	}

	if (loaded) {
		window.addEventListener("storage", () => {
			const storedValue = localStorage.getItem(localStorageKey);
			if (storedValue) {
				_set({
					value: JSON.parse(storedValue) as Value,
					loaded,
				});
			} else {
				_set(initial);
			}
		});
	}

	return {
		subscribe,
		set(value): void {
			saveValueToLocalStorage(value);
			_set({
				value,
				loaded,
			});
		},
		update(updater): void {
			_update(({ value }) => {
				const next = updater(value);
				saveValueToLocalStorage(next);
				return {
					value: next,
					loaded,
				};
			});
		},
	};
}

import { Locale, type LocalizedString } from "./Locale.js";

export enum ProductPhotoStatus {
	ToDo = "ToDo",
	Ready = "Ready",
}

export const productPhotoStatusNameMap: Record<ProductPhotoStatus, LocalizedString> = {
	[ProductPhotoStatus.ToDo]: {
		[Locale.cs]: "TODO",
	},
	[ProductPhotoStatus.Ready]: {
		[Locale.cs]: "Připravená",
	},
};

import type { ButtonProps, ButtonVariant } from "./ButtonProps.js";

export function generateButtonClasses({ variant, disabled, center }: ButtonProps): string {
	const btnVariants: Record<ButtonVariant, string> = {
		primary:
			"group bg-primary border-primary hover:text-primary focus:text-primary active:text-primary text-white hover:bg-white w-fit focus:bg-white w-fit active:bg-white w-fit",
		secondary:
			"group bg-white border-black text-black hover:border-primary focus:border-primary active:border-primary hover:text-primary w-fit focus:text-primary w-fit active:text-primary w-fit",
		secondaryIcon:
			"group max-md:h-8 max-md:w-8 max-md:!p-0 flex-row-reverse bg-white border-black text-black hover:border-primary focus:border-primary active:border-primary hover:text-primary focus:text-primary active:text-primary w-fit",
		transparent: "group text-black flex gap-2 items-center",
		number:
			"group bg-secondary-200 border-transparent text-secondary md:rounded-[1.125rem] rounded-full flex font-semibold items-center hover:bg-secondary-300 focus:bg-secondary-300 active:bg-secondary-300 transition-all duration-300 justify-center w-6 flex-shrink-0 h-6",
	};
	const variantClass = btnVariants[variant ?? "primary"];

	const disabledClass = disabled && "opacity-50 pointer-events-none";

	if (variant !== "transparent" && variant !== "number") {
		return [
			"rounded-full flex gap-[6px] items-center no-underline border px-[1.125rem] leading-5 py-[0.4375rem] font-semibold transition-all duration-300",
			variantClass,
			disabledClass,
			center && "flex justify-center",
		]
			.filter(Boolean)
			.join(" ");
	}

	return [variantClass, disabledClass].filter(Boolean).join(" ");
}

import type { DeepPartial } from "./DeepPartial.js";

export function isObject(item: unknown): item is object {
	return item !== undefined && item !== null && typeof item === "object" && !Array.isArray(item);
}

export function deepMerge<Item>(target: Item, ...sources: DeepPartial<Item>[]): Item {
	if (!sources.length) {
		return target;
	}
	const source = sources.shift();

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!(key in target)) {
					Object.assign(target, { [key]: {} });
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				deepMerge((target as Record<string, any>)[key], source[key]);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
	}

	return deepMerge(target, ...sources);
}

import { ProductSize } from "./ProductSize.js";

export const maxLabels = 3;
export const maxCharactersPerLabel = 20;

export const maxNumbers = 2;
export const minNumber = 1;
export const maxNumber = 999;

export const maxStandingPhotos = 3;

export const minMacaroons = 1;
export const maxMacaroonsPerSize: Record<ProductSize, number> = {
	[ProductSize.Xs]: 3,
	[ProductSize.S]: 8,
	[ProductSize.M]: 12,
	[ProductSize.L]: 20,
};

export const maxFigurines = 3;
export const minFigurinePrice = 350;
